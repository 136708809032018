<template>
  <div class="flex flex-col gap-4">
    <h5>{{ !modelValue.type_id || isOffPlan ? 'Down payment' : 'Deposit payment' }}</h5>
    <DealDownPaymentHeaderText v-if="modelValue.type_id" />
    <Transition name="fade" mode="out-in">
      <div v-if="!modelValue.type_id" class="flex h-[284px] flex-col gap-2 rounded-lg bg-secondary-10 p-2" />
      <div v-else-if="isOffPlan">
        <div v-for="(deal, i) in modelValue.deals" :key="i" class="w-full">
          <Transition name="fade" mode="out-in">
            <div v-if="activeTabIdIndex === i">
              <DealDownPaymentForm
                v-if="modelValue.deals"
                :key="`downPayment-${i}`"
                v-model="modelValue.deals[i]"
                :user-cannot-update-fields="false"
              />
            </div>
          </Transition>
        </div>
      </div>
      <DealCreatePaymentDeposit v-else v-model="modelValue" :v />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { DealCreationDTO } from '~/types'

type Props = {
  v: Validation
  activeTabIdIndex?: number
  isOffPlan?: boolean
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })
</script>

<style scoped></style>
