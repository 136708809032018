<template>
  <div class="flex h-full flex-col gap-4">
    <h5>Deal info</h5>
    <Transition name="fade" mode="out-in">
      <div v-if="!modelValue.type_id" class="flex h-full flex-col gap-2 rounded-lg bg-secondary-10 p-2"></div>
      <div v-else class="flex size-full flex-row gap-3">
        <div class="grid size-full grid-flow-col-dense grid-cols-1 grid-rows-4 gap-3 md:grid-cols-2 md:grid-rows-2">
          <UiInputSelect
            v-if="!isOffPlan"
            id="side"
            v-model="modelValue.side_id"
            :items="sidesItems"
            name="side"
            placeholder="Side"
            label="Side *"
          />
          <UiInputSelect
            id="agent"
            v-model="modelValue.agent_id"
            :items="agentsItems"
            name="agent"
            placeholder="Agent"
            label="Agent *"
            :error="useGetFieldErrors(v, ['agent_id'])"
          />
          <UiInputSelect
            id="source"
            v-model="modelValue.source_id"
            :items="sourceItems"
            name="source"
            placeholder="Source of lead"
            label="Source of lead"
          />
          <UiInputSelect
            id="manager"
            v-model="modelValue.manager_id"
            :items="managerItems"
            name="manager"
            :placeholder="$t('select_from_list_or_type')"
            :label="$t('manager')"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import { ROLES } from '~/constants'
import { useAuthStore } from '~/store/auth'
import { useUiStore } from '~/store/ui'
import type { DealCreationDTO, InputItem, Lead, Team, User } from '~/types'

const authStore = useAuthStore()
const uiStore = useUiStore()

defineEmits(['update:type'])

type Props = {
  type?: number
  lead: Lead
  isOffPlan: boolean
  v: Validation
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })

const agentsItems = ref<InputItem[]>([])
const managerItems = ref<InputItem[]>([])
const sidesItems = ref<InputItem[]>([
  { value: 1, text: 'Buyer' },
  { value: 2, text: 'Seller' },
  { value: 3, text: 'Both' },
])
const sourceItems = ref<InputItem[]>([])

onNuxtReady(async () => {
  try {
    await Promise.all([getUserItems(), getSourceItems()])
  } catch (error: any) {
    uiStore.showSnackBanner('Failed to fetch lists, please try again later', 'error')
  }
})

const getUserItems = async () => {
  agentsItems.value = await useGetAgentsAndManagersItems()
  managerItems.value = await useGetManagersItems()
  const users = await useGetUsers()
  const adminUsers = useSerializeLibraryItems(users.filter((user) => user.role?.code === ROLES.ADMIN))
  managerItems.value.push(...adminUsers)

  // get team manager
  if (authStore.getIsAgent) {
    const response = await useGetTeams()
    const team = response.data.find((t: Team) => t.id === authStore.getUser?.teams?.[0]?.id) as unknown as Team
    const teamManager = team?.users?.filter((u: User) => u.is_manager).map((u: User) => ({ value: u.id, text: u.name }))
    if (teamManager) managerItems.value.push(...teamManager)
  }
}

const getSourceItems = async () => {
  const sources = await useFinanceDealSources()
  sourceItems.value = useSerializeLibraryItems(sources)
}
</script>

<style scoped></style>
