<template>
  <div class="flex flex-col gap-4">
    <Transition name="fade" mode="out-in">
      <div v-if="!typeId" class="flex flex-col gap-2 rounded-lg bg-secondary-10 px-2 py-3">
        <h5 class="text-subhead-1">Deal type</h5>
        <span class="text-body-2">
          Depending on the selected deal type, fields will be generated for deal creation.
        </span>
      </div>
      <div v-else class="flex flex-col gap-2.5">
        <h5 class="text-subhead-1">Deal type</h5>
        <UiInputTextField
          id="client_name"
          v-model="name"
          placeholder="Client name"
          name="client_name"
          :label="`Client name ${isOffPlan ? '*' : ''}`"
          :error="useGetFieldErrors(v, ['name'])"
        />
      </div>
    </Transition>
    <UiInputSelect
      id="type"
      v-model="localTypeId"
      :items="typeItems"
      name="type"
      placeholder="Deal type *"
      label="Type"
      :error="useGetFieldErrors(v, ['type_id'])"
      @update:model-value="showWarningPopup"
    />
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import { POPUPS } from '~/components/dynamic/maps'
import { useUiStore } from '~/store/ui'
import type { InputItem } from '~/types'

const emits = defineEmits(['update:type', 'reset:data'])

const uiStore = useUiStore()

type Props = {
  v: Validation
  isOffPlan?: boolean
}

defineProps<Props>()

const typeId = defineModel<number>('type_id')
const name = defineModel<string>('name')

const localTypeId = ref<number | undefined>(typeId.value)

const typeItems = ref<InputItem[]>([])

const showWarningPopup = () => {
  if (!typeId.value || localTypeId.value === typeId.value) {
    typeId.value = localTypeId.value
    return
  }
  uiStore.showPopup(
    POPUPS.DEAL_CREATION_CHANGE_TYPE,
    {},
    {
      input: () => {
        typeId.value = localTypeId.value
        emits('reset:data')
      },
      cancel: () => {
        localTypeId.value = typeId.value
      },
    }
  )
}

onNuxtReady(async () => {
  typeItems.value = await useGetFinanceDealTypesItems()
})
</script>

<style scoped></style>
