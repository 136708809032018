<template>
  <div class="flex flex-col gap-3">
    <div class="w-full">
      <UiInputDatePicker
        v-model="modelValue.deposit_due_date"
        name="dateRange"
        :start-with-placeholder="!modelValue.deposit_due_date"
        :placeholder="$t('select_date')"
        label="Deposit due date"
      />
    </div>
    <div class="flex flex-col items-center gap-3 lg:flex-row">
      <UiInputTextField
        id="deposit_amount"
        v-model="modelValue.deposit_amount"
        placeholder="Add deposit amount"
        name="deposit"
        suffix="AED"
        type="number"
        label="Deposit, AED"
        class="w-full lg:w-1/2"
      />
      <UiInputTextField
        id="deposit_amount_percentage"
        v-model="percentage"
        placeholder="%"
        name="deposit"
        suffix="%"
        type="percentage"
        label="Deposit, %"
        class="w-full lg:w-1/2"
      />
    </div>

    <UiInputTextarea
      id="deposit_note"
      v-model="modelValue.deposit_note"
      placeholder="Add a note"
      name="deposit_note"
      label="Specify any notes"
      :start-rows="1"
      :max-length="50"
    />
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { DealCreationDTO } from '~/types'

type Props = {
  activeTabIdIndex?: number
  v: Validation
}

defineProps<Props>()

const modelValue = defineModel<Partial<DealCreationDTO>>({ required: true })

const percentage = computed<number>({
  get() {
    if (!modelValue.value?.deposit_amount) return undefined

    const percent = (Number(modelValue.value?.deposit_amount) / Number(modelValue.value?.unit_price)) * 100

    return percent ? Number(percent.toFixed(2)) : undefined
  },

  set(value: number) {
    const downPaymentAmount = (Number(modelValue.value?.unit_price) * value) / 100
    modelValue.value!.deposit_amount = Number(downPaymentAmount.toFixed(2))
  },
})
</script>

<style scoped></style>
